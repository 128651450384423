// Generated by Framer (b35efa8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {rdy_rrL56: {hover: true}};

const cycleOrder = ["rdy_rrL56"];

const variantClassNames = {rdy_rrL56: "framer-v-3zbfb0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "rdy_rrL56", title: bUk8K0VyI = "Privát max diszkont", image: Cb0JdXCsR = {src: new URL("assets/512/odnG1HXpwELmFpx0rxugwM2YBVE.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/odnG1HXpwELmFpx0rxugwM2YBVE.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/odnG1HXpwELmFpx0rxugwM2YBVE.jpg", import.meta.url).href} 1024w, ${new URL("assets/2048/odnG1HXpwELmFpx0rxugwM2YBVE.jpg", import.meta.url).href} 2048w, ${new URL("assets/odnG1HXpwELmFpx0rxugwM2YBVE.jpg", import.meta.url).href} 4032w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "rdy_rrL56", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const isDisplayed = () => {
if (gestureVariant === "rdy_rrL56-hover") return true
return false
}

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-T8IXZ", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<Image {...restProps} background={{alt: "", fit: "fill", ...toResponsiveImage(Cb0JdXCsR)}} className={cx("framer-3zbfb0", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"rdy_rrL56"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"rdy_rrL56-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1c2z6o5"} layoutDependency={layoutDependency} layoutId={"wkdwoM1UZ"} style={{backgroundColor: "rgba(38, 38, 38, 0.25)"}} transition={transition} variants={{"rdy_rrL56-hover": {backgroundColor: "rgba(38, 38, 38, 0.85)"}}}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TW9udHNlcnJhdC03MDA=", "--framer-font-family": "\"Montserrat\", serif", "--framer-font-size": "23px", "--framer-font-weight": "700", "--framer-letter-spacing": "1px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Suzuki hajómotor összeszerelő üzem</motion.p></React.Fragment>} className={"framer-1hx7dyo"} fonts={["GF;Montserrat-700"]} layoutDependency={layoutDependency} layoutId={"BP7BIGMF0"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} transition={transition} verticalAlignment={"center"} withExternalLayout/>)}</motion.div></Image>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-T8IXZ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-T8IXZ * { box-sizing: border-box; }", ".framer-T8IXZ .framer-mpp09a { display: block; }", ".framer-T8IXZ .framer-3zbfb0 { height: 200px; overflow: hidden; position: relative; width: 200px; }", ".framer-T8IXZ .framer-1c2z6o5 { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-T8IXZ .framer-1r27g4u { flex: none; height: 52px; left: calc(50.00000000000002% - 163px / 2); position: absolute; top: calc(50.00000000000002% - 52px / 2); white-space: pre-wrap; width: 163px; word-break: break-word; word-wrap: break-word; }", ".framer-T8IXZ .framer-1hx7dyo { flex: none; height: 50px; left: calc(50.00000000000002% - 175px / 2); position: absolute; top: calc(50.00000000000002% - 50px / 2); white-space: pre-wrap; width: 175px; word-break: break-word; word-wrap: break-word; }", ".framer-T8IXZ .framer-v-3zbfb0 .framer-3zbfb0 { cursor: pointer; }", ".framer-T8IXZ.framer-v-3zbfb0.hover .framer-3zbfb0 { height: 254px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"FDeTQw8NL":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"bUk8K0VyI":"title","Cb0JdXCsR":"image"}
 */
const FramerXkSqjPcIb: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerXkSqjPcIb;

FramerXkSqjPcIb.displayName = "Fram2";

FramerXkSqjPcIb.defaultProps = {height: 200, width: 200};

addPropertyControls(FramerXkSqjPcIb, {bUk8K0VyI: {defaultValue: "Privát max diszkont", displayTextArea: false, title: "Title", type: ControlType.String}, Cb0JdXCsR: {__defaultAssetReference: "data:framer/asset-reference,odnG1HXpwELmFpx0rxugwM2YBVE.jpg?originalFilename=IMG_3679.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerXkSqjPcIb, [{family: "Montserrat", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/XkSqjPcIb:default", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf", weight: "700"}])